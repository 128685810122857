// const moment = require('moment');
// import { gsap } from "gsap";
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { Navigation, Pagination, Autoplay, EffectFade, Swiper } from 'swiper'
// Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
// gsap.registerPlugin(ScrollTrigger);

import Splide from '@splidejs/splide';
export function index() {

	if (document.querySelector('.p-home_mv .splide')) {
		new Splide('.p-home_mv .splide', {
			pauseOnHover: false,
			speed: 2000,
			interval: 10000,
			type: 'fade',
			rewind: true,
			autoplay: true,
			arrows: false,
			easing: 'ease'
		}).mount();
	}

	if (document.querySelector('.p-home_for')) {
		const nav = document.querySelectorAll(".p-home_for_btns .btn a");
		const photo = document.querySelectorAll(".p-home_for_photo img");

		for (let i = 0; i < nav.length; i++) {
			nav[i].addEventListener("mouseover", function (e) {
				document.querySelector(".p-home_for_photo ._active").classList.remove("_active");
				photo[i].classList.add("_active");
			})
		}
	}
}
