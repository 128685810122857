import { index } from "./components/index";

import { contact } from "./components/contact";

import { isSmartPhone } from "./components/isSmartPhone";
import ScrollHint from 'scroll-hint'
import { throttle } from 'throttle-debounce'
new ScrollHint('.js-scrollable', {
	scrollHintIconAppendClass: 'scroll-hint-icon-white', // white-icon will appear
	applyToParents: true,
	i18n: {
		scrollable: 'スクロールできます'
	}
});

index();
contact();

let targets = document.querySelectorAll('a[href*="#"]');
for (let i = 0; i < targets.length; i++) {
	targets[i].addEventListener('click', (e) => {
		// e.preventDefault();
		headerBar.classList.remove('_active');
		spnav.classList.remove('_active');
	})
}
const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
for (let i = 0; i < smoothScrollTrigger.length; i++) {
	smoothScrollTrigger[i].addEventListener('click', (e) => {
		e.preventDefault();
		let href = smoothScrollTrigger[i].getAttribute('href');
		let targetElement = document.getElementById(href.replace('#', ''));
		const rect = targetElement.getBoundingClientRect().top;
		const offset = window.pageYOffset;
		let gap = 0;
		if (isSmartPhone()) {
			gap = 60;
		} else {
			gap = 140;
		}
		const target = rect + offset - gap;
		window.scrollTo({
			top: target,
			behavior: 'smooth',
		});
	});
}

const headerBar = document.querySelector('.header_bar');
const spnav = document.querySelector('.spnav');


headerBar.addEventListener('click', (e) => {
	headerBar.classList.toggle('_active');
	spnav.classList.toggle('_active');
});


window.addEventListener(
	'scroll',
	throttle(100, () => {
		getSideNav();
		getCount();
	}),
	false
);

const sidenav = document.querySelectorAll('.p-in_sidenav li a');
const getSideNav = () => {
	const position = Math.floor(window.innerHeight * .5);

	for (let i = 0; i < sidenav.length; i++) {
		let href = sidenav[i].getAttribute('href');
		if (href.indexOf('#') != -1) {
			let targetElement = document.getElementById(href.replace('#', ''));
			let clientRect = targetElement.getBoundingClientRect();
			let offsetTop = Math.floor(clientRect.top);
			let offsetBottom = Math.floor(clientRect.bottom);

			sidenav[i].classList.remove('_active');
			if (offsetTop < position) {
				sidenav[i].classList.add('_active');
				if (sidenav[i - 1] != null) {      //sidenav[i-1]が存在したらの場合
					sidenav[i - 1].classList.remove("_active")
				}
			}
			if (offsetBottom < 0) {
				sidenav[i].classList.remove('_active');
			}
		}

	}
}





const ms = 800;
const formatter = new Intl.NumberFormat();

const getCount = () => {

	const position = Math.floor(window.innerHeight * 1);


	Array.prototype.forEach.call(document.querySelectorAll('.count'), function (el, i) {
		let clientRect = el.getBoundingClientRect();
		let offsetTop = Math.floor(clientRect.top);
		if (offsetTop < position) {
			if (!el.classList.contains('_active')) {
				el.classList.add('_active');
				const delay = (el.hasAttribute("data-delay")) ?
					el.getAttribute("data-delay") : 0;
				const comma = el.textContent.indexOf(',') > -1;
				const max = parseInt(el.textContent.replace(/,/g, ''), 10);
				el.textContent = '0';
				setTimeout(() => {
					const start = new Date().getTime();
					let id = setInterval(function () {
						const currentTime = Math.min(new Date().getTime() - start, ms);
						const num = Math.floor(max * currentTime / ms);
						el.textContent = comma ? formatter.format(num) : num;
						if (currentTime >= ms) clearInterval(id);
					}, 20);
				}, delay * 200);
			}
		}

	})
}
